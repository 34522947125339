import { formatCurrencyV2 } from "utils/currencyHelpers";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryNameMapper } from "resources/countries-config.json";

export const formatCurrencyPrice = price => {
  let currencyCode = countryNameMapper[getCountryFromUrl() || "US"];
  return formatCurrencyV2(price, currencyCode, { isCartPriceFormat: true });
};

export const getUserTypeLabel = (userType: string) => {
  switch (userType?.toLowerCase()) {
    case "trade":
      return userType;

    case "contract":
      return userType;

    default:
      return "MEMBER";
  }
};

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined || value === "";
};
