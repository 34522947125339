import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext
} from "react";
import {
  IconButton,
  Theme,
  Typography as MuiTypography,
  useMediaQuery
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
import CountrySelectorDialogV2 from "@RHCommerceDev/dialog-country-selector-v2";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import RHArrowIcon from "@RHCommerceDev/icon-arrow";
import memoize from "@RHCommerceDev/utils/memoize";
import {
  countries,
  languages
} from "@RHCommerceDev/resources/countries-config.json";
import clsx from "clsx";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import RHRFlagIcon from "@RHCommerceDev/icon-flag-rhr";
import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  FONT_BARON_SANS_BASICS,
  FONT_BARON_SANS_ULTRA_THIN,
  PAGE_BG_WHITE
} from "@RHCommerceDev/utils/constants";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import RHDialog from "@RHCommerceDev/component-rh-dialog";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import { memoryStorage } from "utils/analytics/storage";
import { postalConfig } from "@RHCommerceDev/resources/postal-config";
import { goToSelectedCountryUrl } from "@RHCommerceDev/utils/getCountryFromUrl";
import { useHistory } from "react-router";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import { handleKeyboardPressWithEvent } from "@RHCommerceDev/utils/accessibility";
import {
  useRhUserAtomValue,
  useUserSessionSetAtom
} from "@RHCommerceDev/hooks-atoms";
import { CountrySiteContext } from "customProviders/CountrySiteProvider";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { countryNameMapper } from "resources/countries-config.json";
import { processEnvServer } from "hooks/useSsrHooks";
import { UseRequestProvider } from "customProviders/Provider";
import { BREAKPOINT_LG, BREAKPOINT_XL } from "utils/constants";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal
} from "@RHCommerceDev/unstyled-ui-components";
import { cn } from "utils/cn";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "8px",
      height: "auto",
      textTransform: "uppercase",
      cursor: "pointer",
      backgroundColor: "transparent",
      borderWidth: "0px",
      padding: "11px 0"
    },
    wrapperConcierge: {
      justifyContent: "center",
      height: "32px",
      borderWidth: "0px",
      padding: "0px !imporatant"
    },
    dropdownText: {
      color: theme.palette.common.black,
      fontFamily: "RHSans-Thin !important",
      fontSize: "11px !important",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        flex: 1
      }
    },
    arrowIcon: {
      width: "8px !important",
      height: "8px !important",
      transform: "rotate(90deg)",
      transition: "transform 0.3s",
      '&[data-open="true"]': {
        transform: "rotate(270deg)"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        transform: "unset"
      }
    },
    dialogPaper: {
      width: "508px",
      height: "375px",
      borderRadius: 0,
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        width: "484px",
        height: "359px"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "100%",
        height: "100%",
        background: "transparent"
      }
    },
    dialogContent: {
      padding: "0px",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        alignItems: "flex-start",
        justifyContent: "flex-start"
      }
    },
    dialogContentV2: {
      backgroundColor: `${PAGE_BG_WHITE}`,
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
        background: "transparent",
        display: "flex"
      }
    },
    closeIcon: {
      position: "absolute",
      top: "16px",
      right: "16px",
      padding: "0 !important",
      "&:focus": {
        outline: "1px solid #000000"
      }
    },
    closeIconV2: {
      width: "1.21875rem",
      height: "1.21875rem",
      color: theme.palette.common.white
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "40px",
      padding: "48px 60px",
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        padding: "40px 48px"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        padding: "0px 0px",
        width: "100%",
        alignItems: "flex-start"
      }
    },
    dialogPaperConcierge: {
      //please keep this classes it is required for concierge
      width: "780px",
      height: "480px",
      borderRadius: 0,
      maxWidth: "780px",
      [theme.breakpoints.down(BREAKPOINT_XL)]: {
        width: "734px"
      },
      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        width: "674px"
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        width: "582px"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "80%",
        height: "100%",
        background: "transparent"
      }
    },
    contentV2: {
      gap: "32px",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "80%",
        height: "100%",
        background: "#f9f7f4"
      }
    },
    contentV2Concierge: {
      //please keep this classes it is required for concierge
      gap: "48px !important",
      justifyContent: "center"
    },
    rightPanel: {
      height: "100%",
      flex: 1,
      justifyContent: "center",
      display: "flex",
      padding: "24px 0"
    },
    dialogTitleFont: {
      fontFamily: `${FONT_BARON_SANS_BASICS} !important`
    },
    dialogTitleFontV2: {
      fontFamily: `${FONT_BARON_SANS_ULTRA_THIN} !important`,
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        fontFamily: "RHSans-Light !important"
      }
    }
  })
);

interface CountrySelectorProps {
  onCountryVisibilityChange?: (value: boolean) => void;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
  onCountryVisibilityChange
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    onCountryVisibilityChange?.(open);
  }, [open, onCountryVisibilityChange]);
  const {
    country,
    language,
    handleSaveCookies,
    setCountry,
    setLanguage,
    setMeasurement
  } = useUserPreferences();

  const setSessionAtom = useUserSessionSetAtom();

  const classes = useStyles();
  const history = useHistory();
  const { setCookieWrapper } = useCookiesWithPermission();
  const {
    FEATURE_IP_BASED_REDIRECT = false,
    FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  } = useEnv();
  const isTailwindComponentEnabled = useTailwindComponent();
  const Typography = isTailwindComponentEnabled
    ? TailwindTypography
    : MuiTypography;
  const { isConcierge } = useAppId();
  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_SM)
  );
  const postalCode = useIsoCookies(["pc"])?.pc;
  const cookieCountry = useIsoCookies(["country_cookie"])?.country_cookie;
  const languageFromCookies = useIsoCookies([
    "language_cookie"
  ])?.language_cookie;
  const localePrefix = useLocalization();
  const selectedLanguage = useMemo(() => {
    const languageLabel = languages.find(
      lang => lang.value === language
    )?.label;
    return languageLabel?.includes("English") ? "English" : languageLabel;
  }, [language]);
  const { setCountrySelector } = useContext(CountrySiteContext);
  const rhUser = useRhUserAtomValue();
  const req: { path?: string } = processEnvServer
    ? useContext(UseRequestProvider)
    : {};
  const isMemberShippage =
    (!processEnvServer
      ? location?.pathname?.includes("membership.jsp")
      : req?.path?.includes("membership.jsp")) || false;

  useEffect(() => {
    let [urlCountry = "US", urlLanguage = "en-US"] = localePrefix
      .split("/")
      .filter(Boolean);
    urlCountry = urlCountry.toUpperCase();

    //redirect user based on ip
    let redirectLanguage = urlLanguage;
    let redirectCountry = (
      rhUser?.akamaiCountryCode || "US"
    ).toLocaleLowerCase();
    let shouldRedirect = false;
    if (yn(FEATURE_IP_BASED_REDIRECT)) {
      if (
        redirectCountry !== urlCountry &&
        !languageFromCookies &&
        postalConfig[redirectCountry.toLocaleLowerCase()] &&
        rhUser
      ) {
        shouldRedirect = true;
        redirectLanguage =
          postalConfig[redirectCountry]?.find(
            item => item.postalCode == (rhUser?.postalCode || postalCode)
          )?.language || language;
      }
    }

    const selectedCountry = countries[urlCountry];
    urlLanguage =
      selectedCountry.availableLanguages.find((lang: string) =>
        lang.includes(urlLanguage)
      ) || "en_US";

    if (country !== urlCountry || language !== urlLanguage) {
      handleSubmit(urlCountry, urlLanguage, false);
    }
    if (shouldRedirect && yn(FEATURE_IP_BASED_REDIRECT)) {
      goToSelectedCountryUrl(redirectCountry, history, redirectLanguage);
    }
  }, [localePrefix]);

  const handleSubmit = useCallback(
    (country: string, language: string, shouldRedirect = true) => {
      const countryValue = countries?.[country]?.defaultValues;
      setCountry(country);
      setLanguage(language);
      setMeasurement(countryValue?.measurement);
      if (isMemberShippage) {
        setSessionAtom(prevData => ({
          ...prevData,
          membershipInfo: { currencyCode: countryNameMapper[country] }
        }));
      }
      memoryStorage.setItem("savedCookiePreference", true);
      const countrySwitch = memoryStorage.getItem("isCountrySwitch");
      if (countrySwitch) {
        document?.body?.dispatchEvent(
          new CustomEvent("cta_click", {
            detail: {
              item: {
                isCountrySwitch: true,
                fromFooter: true,
                country: countryValue?.country,
                language: language
              }
            }
          })
        );
        memoryStorage.removeItem("isCountrySwitch");
      }

      // NOTE: Don't remove postal code as we don't
      // want to override previously set postcode inside handleSaveCookies
      handleSaveCookies(
        {
          country,
          language,
          postalCode:
            !shouldRedirect && cookieCountry === country ? postalCode : null
        },
        undefined,
        true,
        shouldRedirect
      );
      setCookieWrapper("manual_country_selection", true);
      setCountrySelector(true);
    },
    [handleSaveCookies, setCountry, setLanguage, setMeasurement, postalCode]
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      {!isConcierge ? (
        <button
          tabIndex={0}
          onKeyDown={handleKeyboardPressWithEvent(() => setOpen(prev => !prev))}
          className={classes.wrapper}
          role="button"
          onClick={() => setOpen(prev => !prev)}
        >
          <Typography className="text-black text-[11px] sm:flex-1 font-primary-rhsans font-ligth leading-[12.1px] tracking-[0.44px] capitalize ">
            {yn(FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE)
              ? countries[country].label1
              : countries[country].labelEN1}{" "}
            / {selectedLanguage}
          </Typography>
          <RHArrowIcon className={classes.arrowIcon} data-open={open} />
        </button>
      ) : (
        <button
          className={clsx(classes.wrapper, {
            [classes.wrapperConcierge]: isConcierge //please keep this changes required for concierge
          })}
          onClick={() => setOpen(prev => !prev)}
        >
          <RHRFlagIcon country={country} />
        </button>
      )}
      {isTailwindComponentEnabled ? (
        smDown ? (
          <Drawer
            open={open}
            onOpenChange={open => {
              if (!open) {
                onClose?.();
              }
            }}
          >
            <DrawerPortal>
              <DrawerOverlay className="bg-transparent" />
              <DrawerContent
                direction="left"
                className="w-full bg-transparent flex-row"
              >
                <div className="bg-rh-buff w-[80%] h-full flex flex-col gap-y-8">
                  <CountrySelectorDialogV2
                    open={open}
                    onClose={onClose}
                    country={country}
                    language={language}
                    handleSubmit={handleSubmit}
                    className={{ dialogTitleFont: classes.dialogTitleFontV2 }}
                    breakPoint={BREAKPOINT_SM}
                  />
                </div>
                <button className={classes.rightPanel} onClick={onClose}>
                  <RHCloseIcon className={classes.closeIconV2}></RHCloseIcon>
                </button>
              </DrawerContent>
            </DrawerPortal>
          </Drawer>
        ) : (
          <Dialog
            open={open}
            onOpenChange={open => {
              if (!open) {
                onClose?.();
              }
            }}
          >
            <DialogPortal>
              <DialogOverlay />
              <DialogContent
                className={cn(
                  classes.dialogContent,
                  classes.dialogContentV2,
                  !isConcierge
                    ? classes.dialogPaper
                    : classes.dialogPaperConcierge,
                  classes.content,
                  classes.contentV2,
                  {
                    [classes.contentV2Concierge]: isConcierge //please keep this changes required for concierge
                  }
                )}
              >
                <button
                  aria-label="Close"
                  className={cn("outline-none", classes.closeIcon)}
                  onClick={onClose}
                >
                  <RHCloseIcon className="text-base" />
                </button>

                <CountrySelectorDialogV2
                  open={open}
                  onClose={onClose}
                  country={country}
                  language={language}
                  handleSubmit={handleSubmit}
                  className={{ dialogTitleFont: classes.dialogTitleFontV2 }}
                  breakPoint={BREAKPOINT_SM}
                />
              </DialogContent>
            </DialogPortal>
          </Dialog>
        )
      ) : (
        <RHDialog
          id="component-country-selector"
          open={open}
          onClose={onClose}
          fullScreen={smDown}
          DialogContentProps={{
            id: "component-country-selector__dialogContent",
            className: clsx(classes.dialogContent, classes.dialogContentV2)
          }}
          showHeader={false}
          ariaLabel="Country Selector Dialog"
          PaperProps={{
            className: !isConcierge
              ? classes.dialogPaper
              : classes.dialogPaperConcierge //please keep this changes required for concierge
          }}
          className={`${!smDown && "bg-gray-500/40"} !justify-center`}
        >
          <div
            className={clsx(classes.content, classes.contentV2, {
              [classes.contentV2Concierge]: isConcierge //please keep this changes required for concierge
            })}
          >
            {!smDown && (
              <IconButton
                disableRipple
                aria-label="Close"
                className={classes.closeIcon}
                onClick={onClose}
                size="large"
              >
                <RHCloseIcon className="text-base" />
              </IconButton>
            )}

            <CountrySelectorDialogV2
              open={open}
              onClose={onClose}
              country={country}
              language={language}
              handleSubmit={handleSubmit}
              className={{ dialogTitleFont: classes.dialogTitleFontV2 }}
              breakPoint={BREAKPOINT_SM}
            />
          </div>
          {smDown && (
            <div className={classes.rightPanel} onClick={onClose}>
              <RHCloseIcon className={classes.closeIconV2}></RHCloseIcon>
            </div>
          )}
        </RHDialog>
      )}
    </>
  );
};

export default memoize(CountrySelector);
