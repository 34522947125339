import analyticsLoader from "analytics/loader";
import clsx from "clsx";
import RHImageV2 from "@RHCommerceDev/rh-image-component";
import RHLink from "component-rh-link";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { usePageContent } from "customProviders/LocationProvider";
import { SaleContextFilter } from "graphql-client/queries/app";
import he from "he";
import { useRhUserAtomValue } from "hooks/atoms";
import useAppData from "hooks/useAppData";
import { useParams2 } from "hooks/useParams";
import { processEnvServer } from "hooks/useSsrHooks";
import useTypographyStyles from "hooks/useTypographyStyles";
import React, {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from "react";
import { useInView } from "react-intersection-observer";
import { memoryStorage } from "utils/analytics/storage";
import {
  BREAKPOINT_MD,
  IMAGE_ASPECT_RATIO,
  PAGE_BG_GREY
} from "utils/constants";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography as MuiTypography,
  useMediaQuery
} from "@mui/material";
import memoize from "utils/memoize";
import useGetPDPRedirectPath from "hooks/useGetPDPRedirectPath";
import { getMemberSavingsText } from "utils/memberSavingsTextUtils";
import { useTailwindComponent } from "@RHCommerceDev/hooks-use-tailwind-component";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productNamePrice: {
      "& .priceSpan": {
        display: "block",
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: "inline"
        },
        ".cols-12 &": {
          display: "inline"
        }
      },

      "& .priceSeperator": {
        ".cols-12 &": {
          display: "inline"
        },
        display: "none",
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: "inline"
        }
      }
    }
  })
);
export interface ProductCardProps {
  data: Product | CategoryProduct;
  showPriceRange?: boolean;
  saleContextFilter?: SaleContextFilter;
  upsell?: string;
  objectFit?: string;
  cardContentRef?: (ref: HTMLDivElement) => void;
  color?: string;
  colorBg?: string;
  isImageEager?: boolean;
  onViewSelectItemsOnSaleClick?: () => void;
  drivedStyles?: CSSProperties;
  cardIndex?: number;
  isObserver?: boolean;
  setActiveDot?: Dispatch<SetStateAction<number>>;
  maxContainerHeight?: number;
  maxUpsellHeight?: number[];
  setMaxUpSetHeight?: Dispatch<SetStateAction<number[]>>;
  totalUpSell?: number;
  RHImageStyles?: any;
  cardMediaStyles?: any;
  RHImageContainerProps?: any;
  isUpsellProduct?: boolean;
  imageSkeletonProps?: {
    className?: string;
  };
  RHImageProps?: any;
  cardStyles?: any;
}

export const ProductCard: FC<ProductCardProps> = memoize(
  // TODO: analytics remove "upsell" param here
  ({
    data,
    showPriceRange,
    cardContentRef,
    color,
    objectFit,
    colorBg = PAGE_BG_GREY,
    cardIndex = 0,
    isObserver = false,
    setActiveDot = () => {},
    maxContainerHeight = 0,
    maxUpsellHeight = [],
    setMaxUpSetHeight = () => {},
    totalUpSell = 0,
    RHImageStyles = {},
    cardMediaStyles = {},
    RHImageContainerProps = {},
    isUpsellProduct = false,
    imageSkeletonProps = {},
    RHImageProps = {},
    cardStyles = {}
  }) => {
    const classes = useStyles();
    const isTailwindComponentEnabled = useTailwindComponent();
    const Typography = isTailwindComponentEnabled
      ? TailwindTypography
      : MuiTypography;
    const typographyClasses = useTypographyStyles({
      keys: ["rhBaseH7", "rhBaseCaption", "rhBaseBody1"]
    });
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const [isLoading, setIsLoading] = useState("success");

    const { params } = useParams2<{ [key: string]: string }>(
      { version: "" },
      { toLowerCase: true }
    );
    const { userType } = useRhUserAtomValue();

    const { ref: observerRef, inView } = useInView();

    const isLatestPDPLayout =
      memoryStorage.getItem("newPdpLayout") || params.version === "v2";

    useEffect(() => {
      if (isObserver) {
        if (inView) {
          setActiveDot(cardIndex);
        }
      }
    }, [inView]);

    const { pageContent } = usePageContent();

    const priceRangeDisplay = useMemo(() => {
      const isMultiSkuPopulated =
        !!data.priceRangeMultiSkuDisplay &&
        !data.priceRangeMultiSkuDisplay.isUnavailable &&
        !data?.metaProduct;

      return isMultiSkuPopulated
        ? {
            ...data.priceRangeDisplay,
            listPrices: data?.priceRangeMultiSkuDisplay?.listPrices,
            memberPrices: data?.priceRangeMultiSkuDisplay?.memberPrices,
            skulowestMemberPrice:
              data?.priceRangeMultiSkuDisplay?.skulowestMemberPrice,
            nextGenDrivenOnSale:
              data?.priceRangeMultiSkuDisplay?.nextGenDrivenOnSale
          }
        : data.priceRangeDisplay;
    }, [data.priceRangeDisplay, data.priceRangeMultiSkuDisplay]);

    const dynamicMemberSavingsText = getMemberSavingsText(
      pageContent,
      Number(data?.saleInfo?.percentSaleSkus),
      Number(data?.saleInfo?.memberSavings?.memberSavingsMin),
      Number(data?.saleInfo?.memberSavings?.memberSavingsMax)
    );

    if (data.id === "BLANK") {
      return null;
    }

    const imageStyle = useMemo(() => {
      let style;
      try {
        style = data?.rhr ? JSON.parse(data?.pgCropRules) : {};
      } catch (error) {
        style = {};
      }
      if (isLatestPDPLayout) {
        return { ...style, maxHeight: "100%" } as CSSProperties;
      }
      return style as CSSProperties;
    }, [data?.pgCropRules, data?.rhr]);

    const triggerAnalyticsEvent = () => {
      if (!processEnvServer) {
        const itemList = [
          {
            item_name: data?.displayName,
            item_id: data?.id,
            item_category: null,
            item_variant: null,
            quantity: null,
            price: null,
            item_list_name: window?.location?.href?.includes("/search/")
              ? "search"
              : window?.location?.href?.includes("products.jsp")
              ? "pg"
              : null
          }
        ];

        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.SELECT_ITEM.INT_TYPE,
            {
              itemList,
              item_list_name: "PG",
              id: data?.id,
              displayName: data?.displayName
            }
          )
        );
      }
    };

    const redirectPath = useGetPDPRedirectPath(data);
    const redirectPathSale = useGetPDPRedirectPath(data, true);

    return (
      <div
        data-testid={`rh-test-container`}
        key={`reveal-${data.id}`}
        className={clsx([
          "opacity-0",
          "animate-fadeInUp",
          isLatestPDPLayout
            ? `w-full sm:w-[296px] md:w-[253px] lg:w-[323px] xl:w-[429px]`
            : "",
          "!animate-custom-animation !opacity-100",
          // can be removed below code once the feature flag Embla carousel is removed new upSell code has its own product details code
          isUpsellProduct ? "pl-5" : ""
        ])}
      >
        <Card
          elevation={0}
          square
          style={{
            backgroundColor: colorBg,
            ...(maxContainerHeight ? { height: maxContainerHeight } : {}),
            position: "relative"
          }}
        >
          <RHLink
            data-testid={`productCardLink-${data.id}`}
            to={redirectPath}
            tabIndex={-1}
            underline="none"
            onClick={triggerAnalyticsEvent}
          >
            {data?.isShopByRoom ? (
              <CardMedia
                className={`[&.MuiGrid-root.MuiGrid-container]:justify-center `}
                style={{
                  ...data?.imageContainerStyle,
                  ...cardMediaStyles,
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  // can be removed below code once the feature flag Embla carousel is removed
                  ...(isUpsellProduct && {
                    height: data?.imageContainerStyle?.height ?? 400
                  }),
                  ...cardStyles
                }}
              >
                <RHImageV2
                  src={data?.imageUrl}
                  alt={data.displayName}
                  className="object-contain mx-auto"
                  preset={mdUp ? "pg-card-md" : "pg-card-xs"}
                  style={{
                    ...(data?.imageStyle || {}),
                    maxWidth: "100%",
                    ...RHImageStyles
                  }}
                  containerProps={RHImageContainerProps}
                  onLoad={() => setIsLoading("success")}
                  onError={() => setIsLoading("error")}
                  skeletonComponent={() => null}
                  {...RHImageProps}
                />
              </CardMedia>
            ) : (
              <CardMedia className="[&.MuiGrid-root.MuiGrid-container]:justify-center flex flex-col justify-end">
                <RHImageV2
                  src={data?.imageUrl}
                  alt={data.displayName}
                  className={clsx("w-auto h-auto max-w-full", {
                    "w-full sm:w-[296px] md:w-[253px] lg:w-[323px] xl:w-[429px]":
                      isLatestPDPLayout
                  })}
                  preset={mdUp ? "pg-card-md" : "pg-card-xs"}
                  style={{
                    ...imageStyle,
                    aspectRatio: IMAGE_ASPECT_RATIO["productCard"],
                    objectFit: objectFit
                  }}
                  containerProps={{
                    style:
                      maxUpsellHeight.length >= totalUpSell && isLatestPDPLayout
                        ? { height: `${Math.max(...maxUpsellHeight)}px` }
                        : {}
                  }}
                />
              </CardMedia>
            )}
          </RHLink>
          <div className="flex justify-center">
            <span ref={observerRef}></span>
          </div>
          <RHLink
            data-testid={`productCardLink-${data.id}`}
            to={redirectPath}
            tabIndex={-1}
            underline="none"
            bypassForceReload={
              data?.priceRangeDisplay?.priceMessage &&
              (data?.priceRangeDisplay?.salePrices?.[0] ||
                data?.priceRangeDisplay?.priceMessagePrice)
                ? true
                : false
            }
            onClick={triggerAnalyticsEvent}
          >
            <CardContent className="!p-0" ref={cardContentRef}>
              <div
                className={`${isLatestPDPLayout ? "mt-8" : "mt-5"} ${
                  data?.rhr ? "text-center" : "text-left"
                }`}
              >
                <Typography
                  data-testid={`display-test-id`}
                  className={`${
                    isLatestPDPLayout
                      ? "self-stretch flex-grow-0 text-[13px] font-primary-light font-extralight !leading-none text-black text-center tracking-[0.26px]"
                      : "font-thin text-black tracking-normal !leading-[15.6px]"
                  } !uppercase pr-6 mb-1 ${
                    isLatestPDPLayout || data?.rhr ? "text-center" : "text-left"
                  } ${classes.productNamePrice} ${
                    typographyClasses.rhBaseBody1
                  }`}
                >
                  <span
                    style={{
                      color: color,
                      background: colorBg
                    }}
                    className="text-[13px] font-primary-rhroman"
                    dangerouslySetInnerHTML={{
                      __html: `${data.newProduct ? `${pageContent.NEW} ` : ""}`
                    }}
                  ></span>
                  <span
                    style={{
                      color: color,
                      background: colorBg
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${he.decode(data.displayName)}`
                    }}
                  ></span>
                </Typography>

                {showPriceRange && !!data?.priceRangeDisplay && (
                  <RHRPriceDisplay
                    listPrice={priceRangeDisplay?.listPrices?.[0]!}
                    memberPrice={priceRangeDisplay?.memberPrices?.[0]!}
                    topLabel={
                      data.priceRangeDisplay?.overridePriceLabel ||
                      pageContent?.["STARTING_AT"]
                    }
                    onSale={priceRangeDisplay?.nextGenDrivenOnSale!}
                    skulowestMemberPrice={
                      priceRangeDisplay?.skulowestMemberPrice!
                    }
                    showSaleMessage={
                      Number(data?.saleInfo?.percentSaleSkus) === 0 ||
                      Number(data?.saleInfo?.percentSaleSkus) === 100
                        ? false
                        : true
                    }
                    userType={userType!}
                    pageContent={pageContent}
                    computedSalePageContent={dynamicMemberSavingsText}
                    variant={"small"}
                    centerAlignFlag={Boolean(data?.rhr)}
                    showMembershipProductPrice={
                      data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                      "true"
                    }
                    showGiftCardPrice={
                      data?.uxAttributes?.giftCert?.toLowerCase() === "true"
                    }
                    isCustomProduct={data?.customProduct!}
                    isSaleFilterEnabled={false}
                    saleUrl={redirectPathSale}
                  />
                )}
              </div>
              {/* size and fabrics cta here */}
            </CardContent>
          </RHLink>
        </Card>
      </div>
    );
  }
);

ProductCard.defaultProps = {
  showPriceRange: true
};

export default memoize((props: Omit<ProductCardProps, "saleContextFilter">) => {
  const { app } = useAppData();

  return <ProductCard {...props} saleContextFilter={app.saleContextFilter} />;
});
