import { DialogProps } from "@mui/material/Dialog";
import { DialogContentProps } from "@mui/material/DialogContent";
import RHDialogTitle from "component-rh-dialog-title";
import React, { FC } from "react";
import { Dialog, DialogContent, DialogTitleProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export interface RHDialogProps extends Omit<DialogProps, "children"> {
  onBack?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  title?: string;
  titleClassNames?: string;
  dialogTitleStyles?: React.CSSProperties;
  DialogTitleProps?: DialogTitleProps;
  DialogContentProps?: DialogContentProps;
  fullScreen?: boolean;
  banner?: boolean;
  showHeader?: boolean;
  ariaLabel?: string;
  closeIconClass?: string;
}

const bannerStyles = makeStyles({
  container: {
    alignItems: "flex-start"
  },
  paper: { margin: 0, width: "100vw" }
});

export const RHDialog: FC<RHDialogProps> = ({
  onBack,
  title,
  titleClassNames,
  dialogTitleStyles,
  children,
  DialogTitleProps,
  DialogContentProps,
  fullScreen = true,
  banner = false,
  showHeader = true,
  id,
  ariaLabel,
  closeIconClass,
  ...rest
}) => {
  const bannerClasses = bannerStyles();
  const scroll = rest.scroll || "body";
  return (
    <Dialog
      id={id || "component-rh-dialog"}
      aria-labelledby={showHeader ? "component-rh-dialog-title" : ""}
      aria-label={showHeader ? "" : ariaLabel}
      fullScreen={fullScreen}
      fullWidth={banner}
      classes={banner ? bannerClasses : undefined}
      role="dialog"
      {...rest}
      scroll={scroll}
    >
      {showHeader && (
        <RHDialogTitle
          {...DialogTitleProps}
          onBack={onBack}
          title={title}
          titleClassNames={titleClassNames}
          style={dialogTitleStyles}
          onClose={rest.onClose}
          closeIconClass={closeIconClass}
        />
      )}
      <DialogContent {...DialogContentProps}>{children}</DialogContent>
    </Dialog>
  );
};

RHDialog.defaultProps = {};

export default RHDialog;
