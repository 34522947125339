import React, { FC, useContext, useEffect } from "react";
import { useTheme, DialogContent } from "@mui/material";
import { SwipeableDrawerProps } from "@mui/material/SwipeableDrawer";
import RHDialogTitle from "component-rh-dialog-title";
import Drawer from "component-drawer";
import { CountrySiteContext } from "customProviders/CountrySiteProvider";

export interface RHMenuDrawerProps
  extends Omit<SwipeableDrawerProps, "onOpen" | "onClose"> {
  title?: string;
  closeIconClassnames?: string;
  closeButtonRootClassnames?: string;
  titlePaddingClassNames?: string;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onBack?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  contentStyle?: { [key: string]: any };
  subTitle?: string;
  isDark?: boolean;
}

export const RHMenuDrawer: FC<RHMenuDrawerProps> = ({
  title,
  onClose,
  onBack,
  children,
  contentStyle,
  isDark = false,
  id,
  ...rest
}) => {
  const theme = useTheme();

  const { drawerOpen, onDrawerOpen } = useContext(CountrySiteContext);

  const { open, anchor } = rest;

  useEffect(() => {
    if (open && (anchor == "right" || anchor == "bottom") && !drawerOpen) {
      onDrawerOpen();
    }
  }, [open, anchor]);

  const customClose = (e, reason) => {
    onClose?.(e, reason);
    if (drawerOpen) onDrawerOpen();
  };

  return (
    <Drawer
      id={id || "component-rh-menu-drawer"}
      PaperProps={{
        className: "absolute"
      }}
      scrollToTopEnable={false}
      {...rest}
      onClose={customClose}
      ModalProps={{
        style: {
          zIndex: theme.zIndex.appBar + 1
        }
      }}
    >
      <RHDialogTitle
        title={title}
        onBack={onBack}
        titlePaddingClassNames={rest.titlePaddingClassNames}
        closeButtonRootClassnames={rest.closeButtonRootClassnames}
        onClose={customClose}
        subTitle={rest?.subTitle}
        anchor={rest.anchor}
        closeIconClassnames={rest.closeIconClassnames}
        style={{
          backgroundColor: theme.palette.background.default
        }}
      />
      <DialogContent
        className={"!pt-0"}
        style={{
          ...contentStyle
        }}
      >
        {children}
      </DialogContent>
    </Drawer>
  );
};

RHMenuDrawer.defaultProps = {
  anchor: "left",
  open: false
};

export default RHMenuDrawer;
